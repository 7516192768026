import { Component, Fragment, useState } from 'react';
import { SmallNavbar } from '../../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSort,faList, faCheck, faTrash, faEdit, faUser,faBuilding, faCoins, faChartLine, faTimes, faFile } from '@fortawesome/free-solid-svg-icons';
import { Card, InfoCard, ChipTable } from '../../components/Card';
import Datatable, { IconSort } from '../../components/Datatable';
import Modal    from '../../components/Modal';
import Spinner    from '../../components/Spinner';
import { getAllTransaksiBerjalan, deleteTransaksi, getCurrentTime } from '../../api/Transaksi';
import toast, { Toaster } from 'react-hot-toast';
import { Toast as ToastComponent } from '../../components/Toast';
import { Navigate  } from "react-router-dom";
import { InputText, Select } from "../../components/Input";
import { approveTerima, approveTolak } from '../../api/Transaksi';
import { getKeranjangTransaksi } from '../../api/Keranjang';
import { ActiveDropdown } from '../../config/Config';
import FullLoader from '../../components/FullLoader';
import { NavLink }   from 'react-router-dom';
import { numberFormat, invoiceExpired } from '../../api/Value';
import TabApproval from '../../components/TabApproval';
export default class BelumDiajukan extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: [],
            dataFilter: [],
            detailModal: false,
            detail: {
                transaksi: {},
                keranjang: []
            },
            ready: false,
            type_data: "semua",
            user: JSON.parse(sessionStorage.getItem("ROLE_PENERIMAAN")),
            myDeleteForm: false,
            id_transaksi: "",
            loader: false,
            localTime: "2022-07-18T04:10:28.5547013"
        }
    }
    componentDidMount  = () => {
        
        // console.log(this.state.user)
        localStorage.setItem("BACK", JSON.stringify({ to: "/app/transaksi/berjalan", title: "Transaksi Berjalan" }));
        const act = document.getElementById("Transaksi_nav");
        if(act){ 
                act.classList.add("border-b-4")
                act.classList.add(ActiveDropdown())
        }
        this.getAll()
    }
    getAll = async () => {
        const self = this;
        const now = new Date()
        let local   = `${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`
            
    
        // await getCurrentTime().then(res => {
            
        //     if (res.status != 500) {
        //         local = res.datetime
        //     }
        // })
        
        await getAllTransaksiBerjalan().then((result) => {
            if (result.status === 200) {
                const data = result.data?.map((row) => {
                    const isExpired = invoiceExpired(row.dt_transaksi_created, JSON.parse(row.info_transaksi), row.tipe_transaksi, local)
                   row.isExpired  = isExpired ? "EXPIRED" : ""; 

                    return row
                })
                self.setState({
                    data: result.data !== null ? result.data : [],
                    dataFilter: result.data !== null ? result.data : [],
                    ready: true
                })
            }else{
                self.setState({
                    ready: true
                })
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Gagal Mendapatkan Transaksi Berjalan" bg="orange"/>
                })
            }
        //    console.log(result)
        })
   
    }
    componentWillUnmount = () => {
        const act = document.getElementById("Transaksi_nav");
        if (act !== null) {

            act.classList.remove("border-b-4")
            act.classList.remove(ActiveDropdown())
        }
    }
    openDeleteForm = (id_transaksi) => {
        this.setState({
            myDeleteForm: true,
            id_transaksi: id_transaksi
        })
    }
     closeModalDetail  =() => {
        this.setState({
            detail: {
                keranjang: [],
                transaksi: {}
            },
            detailModal: false
        })
    }
     detailTransaksi = (index) => {
        
        let data = this.state.data[index];
        const self = this;
        getKeranjangTransaksi({ id_transaksi: data.id_transaksi }).then((result) => {
            if (result.status === 200) {
                let detail = self.state.detail;
                detail["keranjang"] = result.data.map((x) => {
                    return { ...x,info_keranjang: JSON.parse(x.info_keranjang) }
                    
                });
                detail["transaksi"] = { ...data, info_pelanggan: JSON.parse(data.info_pelanggan), info_transaksi: JSON.parse(data.info_transaksi), user_pembuat: JSON.parse(data.user_pembuat), history: JSON.parse(data.history)  }
               self.setState({
                   detail: detail,
                   detailModal: true
               })
               
            }else{
        //        console.log(result)
            }
        })
    }
    handleType = (nameType) => {
        this.setState({
            type_data: nameType,
            dataFilter: (nameType === "semua") ? this.state.data : this.state.data.filter((r) => {
                            return r.tipe_transaksi === nameType
                        })
        })
    }
    submitDelete = () => {
        const self= this;
        this.setState({
            loader: true
        })
        deleteTransaksi({ id_transaksi: this.state.id_transaksi }).then((res) => {
             if (res.status === 200) {
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Transaksi Berhasil di hapus" bg="orange"/>
                })
                self.getAll();
                
            }else{
                toast.custom((t) => {
                    return <ToastComponent param={t} message="Transaksi Gagal di hapus" bg="red"/>
                })
           //     console.log(res)
            }

            self.setState({
                    loader: false,
                    myDeleteForm: false
                })
        })
    }
    closeDeleteForm = () => {
        this.setState({
            myDeleteForm: false
        })
    }
    render(){
        const { openDeleteForm, closeModalDetail, detailTransaksi, handleType, submitDelete, closeDeleteForm } = this;
        const { dataFilter, detailModal, detail, ready, type_data, user, loader, id_transaksi, myDeleteForm, localTime }           = this.state;
        const isDeleted = user.aksi_transaksi_berjalan.indexOf("delete") !== -1;
        if (ready === false) {
            return <FullLoader />
        }
        return (
            <div className="p-5 animate-fadein">
                        <Toaster position="bottom-right"/>
            

                <SmallNavbar 
                    left={<Fragment>
                            
                            <FontAwesomeIcon icon={faList} />  
                            <div className="flex-initial ml-2 -mt-1 mb-2" >
                                Berjalan
                            </div>
                        </Fragment>}
                />
                <TabApproval type_data={type_data} handleType={handleType} />
                <div className="pt-3">
                    <Card>
                        <TableTransaksi localTime = { localTime } openDeleteForm={ openDeleteForm } DataRender={dataFilter} detailTransaksi={detailTransaksi} isDeleted={ isDeleted } />
                    </Card>
                </div>
                <Modal
                    Title = {<span className="text-white">Hapus Transaksi</span>}
                    Body  = {<div className="text-white mt-5 mb-2">Apakah Anda Yakin?</div>}
                    Footer = {<ModalDeleteFooter submitDelete={submitDelete} loader={loader} closeModal = {closeDeleteForm}/>}
                    closeModal = {closeDeleteForm}
                    isOpen = {myDeleteForm}
                    Container = "max-w-md bg-red-600"
                />
            </div>
        )
    }
}

const TableTransaksi = ({ openDeleteForm, DataRender,detailTransaksi, isDeleted, localTime }) => {
 
  const TheadComponent = ({ sortData, sort, order_by }) => {
    return (
      <thead>
        <tr>
        <td onClick={() => sort("id_transaksi")} className=" text-center border-2 border-gray-200 pl-2 pr-2" rowSpan="2">ID {IconSort(order_by, "id_transaksi")}</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Uraian/Bidang/Pembuat/Tipe</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Pelanggan/Jumlah/Progress/Penolakan</td>
            <td className=" border-2 border-gray-300 p-2 text-center">Aksi</td>

           
        </tr>
      </thead>
      
    )
  }
  return (
    <div>
        <Datatable 
          Head={TheadComponent}
          Data={DataRender}
          Entries={[]}
          Body={(row, index) => {
            let mod = (index%2 == 0) ? " bg-gray-100 " : "";
            let user_info = {}
            let history = [];
            let user_pembuat = {}
            let info_transaksi= {}
            let tgl_awal = ""
            let tgl_akhir = ""
            try {
                user_pembuat = JSON.parse(row.user_pembuat)
                user_info    = JSON.parse(row.info_pelanggan)
                history      = JSON.parse(row.history)
                info_transaksi = JSON.parse(row.info_transaksi)

                tgl_awal = info_transaksi.tgl_awal.split("-").reverse().join("/")
                tgl_akhir = info_transaksi.tgl_akhir.split("-").reverse().join("/")
            } catch (error) {
            // console.log(error, row.history)   
            }
            
            return (
              <tr key={ index }>
                <td className={"border-2 border-gray-300 p-2 align-top text-center" + mod} width="5%"> 
                    { row.id_transaksi } 
                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                    <NavLink to={"/app/detail_transaksi/" + row.id_transaksi}>
                    <ChipTable icon={<FontAwesomeIcon icon={faList} />} text={<span><span className="cursor-pointer underline text-blue-500">{row.uraian} 
                        
                        </span>
                        {row.isExpired =="EXPIRED" ?<span className="bg-red-600 ml-2 rounded-md text-sm pl-2 pr-2 decoration-transparent text-white font-bold">EXPIRED</span> : null}
                    </span>} />
                    </NavLink>
                        <ChipTable icon={<FontAwesomeIcon icon={faBuilding} />} text={
                            <span>
                                { row.bidang }

                                <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_awal }</span>
                                <span className="bg-blue-600 text-xs px-2 ml-2 rounded-md text-slate-50 "> { tgl_akhir }</span>
                            </span>
                            
                        } />
                        <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={user_pembuat.nama} />
                        <ChipTable icon={<FontAwesomeIcon icon={faFile} />} text={row.tipe_transaksi} />

                </td>
                <td className={"border-2  border-gray-300 p-2 align-top " + mod} width="53%">
                        <ChipTable icon={<FontAwesomeIcon icon={faUser} />} text={<span>{user_info.nama}</span>} />

                    <ChipTable icon={<FontAwesomeIcon icon={faCoins} />} text={numberFormat(`${row.jumlah_transaksi}`)} />
                    <ChipTable icon={<FontAwesomeIcon icon={faChartLine} />} text={row.progress_transaksi} />
                    <ChipTable icon={<FontAwesomeIcon icon={faTimes} />} text={

                        
                        (row.progress_transaksi.indexOf("ditolak") !== -1 && history.length > 0) ? 
                        <div className="text-red-600">
                            <span className="font-bold">{ 
                                history[history.length-1]["username"] } ({ history[history.length-1]["role"] }) : </span>
                           { history[history.length-1]["aksi"]}
                        
                        </div>

                        : 
                        null
                    } />

                </td>
                    <td className={"border-2  border-gray-300 p-3 align-top text-center " + mod}>
                        {
                            isDeleted ?
                            <button onClick={() => openDeleteForm(row.id_transaksi)} className="active:shadow-none bg-white active:bg-red-300 hover:bg-red-200 border-red-500 border-2 shadow-md text-red-500 p-1  rounded-full w-10">
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>
                            :
                            null
                        }    
                        
                    </td>
               
              </tr>
            )
          }}
        />
    </div>
  )
}

const ModalDeleteFooter = ({ closeModal, submitDelete, loader }) => {
  

    return(
        <div className="text-right flex justify-end">
            {
                (!loader) ?
                    <div>
                        <button className="font-bold bg-gray-300 p-2 shadow-md hover:bg-gray-400 active:bg-gray-500 active:shadow-none rounded-md w-32  text-gray-800" onClick={closeModal}>Batal</button>
                        <button onClick={() => submitDelete("delete")} className=" font-bold bg-red-700 ml-2 p-2 shadow-md hover:bg-red-800 active:bg-red-900 active:shadow-none rounded-md w-32 text-white">
                                Yakin
                        </button>
                    </div>
                : 
                <button className="opacity-70 cursor-not-allowed flex font-bold bg-red-900 ml-2 p-2 rounded-md w-52 text-white">
                    <div className="w-1/4">
                        <Spinner 
                            color="rgb(255 255 255)"
                            width="30px"
                            height="30px"
                        />
                    </div>
                    <div className="pt-1">
                        Mohon Tunggu
                    </div>
                </button>
            }
        </div>
    )
}